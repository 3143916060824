import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import GatsbyImage, { GatsbyImageProps } from "gatsby-image"

// Utils
import { findBreakpoint } from "../utils/styleHelpers"

// Styling
import textStyles from "../styles/typography"
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import Seo from "../components/Seo"
import ArticleHeader from "../components/ArticleHeader"
import Container from "../components/Container"
import { GridRow, GridCol } from "../components/grid"
import Text from "../components/Text"

// Types
interface IProps {
  data: any
}

export const query = graphql`
  query Article($uid: String!, $type: String!) {
    prismicNews(uid: { eq: $uid }, type: { eq: $type }) {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        title {
          text
        }
        image {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        content {
          html
        }
        creditsAndSources: credits_and_sources {
          html
        }
      }
    }
    prismicStories(uid: { eq: $uid }, type: { eq: $type }) {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        title {
          text
        }
        image {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        content {
          html
        }
        creditsAndSources: credits_and_sources {
          html
        }
      }
    }
  }
`

const Article: React.FC<IProps> = ({ data }) => {
  const rawPageData = data.prismicNews?.data ?? data.prismicStories?.data

  const metaTitle = rawPageData.metaTitle ?? rawPageData.title.text
  const metaDescription = rawPageData.metaDescription

  const title = rawPageData.title.text
  const mainImage = rawPageData.image
  const content = rawPageData.content.html
  const creditsAndSources = rawPageData.creditsAndSources.html

  return (
    <>
      <Seo title={metaTitle} description={metaDescription} />
      <ArticleHeader />
      <Container>
        <GridRow>
          <GridCol m={6} mOffsetLeft={2} l={6} lOffsetLeft={3}>
            {title && (
              <Title
                marginTop={56}
                marginBottom={24}
                tag="h1"
                textStyle="title"
              >
                {title}
              </Title>
            )}
            {mainImage.fluid && (
              <StyledGatsbyImage
                fluid={mainImage.fluid}
                alt={mainImage.alt ?? ""}
              />
            )}
            {content && (
              <Content dangerouslySetInnerHTML={{ __html: content }} />
            )}
            {creditsAndSources && (
              <CreditsAndSources
                dangerouslySetInnerHTML={{ __html: creditsAndSources }}
              />
            )}
          </GridCol>
        </GridRow>
      </Container>
    </>
  )
}

const sharedImgStyles = css`
  margin: 0 ${findBreakpoint("s")?.gutterWidth! * -1}px 32px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-left: 0;
      margin-right: 0;
    `
  )}
`

const Title = styled(Text)`
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 64px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-top: 120px;
    `
  )}
`

const StyledGatsbyImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${sharedImgStyles}
`

const Content = styled.article`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    ${textStyles.subHeading}
  }

  a {
    color: ${colors.link};
  }

  p.block-img {
    ${sharedImgStyles}
  }

  img {
    width: calc(100% + ${findBreakpoint("s")?.gutterWidth! * 2}px);
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 56px;
      }

      img {
        width: 100%;
      }
    `
  )}
`

const CreditsAndSources = styled.div`
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: ${colors.black};
  padding: 24px 0;

  p {
    margin: 0;
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 56px;
    `
  )}
`

export default Article
